<template>
  <v-container fluid>
    <!--BOC:[breadcrumb]-->
    <ABreadcrumb :items="breadcrumb.items" />
    <!--EOC-->
    <div class="d-flex mb-3">
      <h2>Leads</h2>
      <v-spacer />
      <v-btn color="primary">Auto Assign</v-btn>
      <v-btn class="ml-2" color="success">New Lead</v-btn>
    </div>
    <v-stepper non-linear class="mb-3" dense v-model="step">
      <v-stepper-header>
        <v-stepper-step
          step="1"
          editable
          @click="$router.push({ name: 'MockupLeadLeadBrowse' })"
        >
          New Lead
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step
          step="2"
          editable
          @click="$router.push({ name: 'MockupLeadLeadBrowseStep2' })"
        >
          Assigned
        </v-stepper-step>
      </v-stepper-header>
    </v-stepper>
    <div ref="table"></div>
    <MNote
      :text="[
        'Salesperson actions: Lock lead > Contact > Verify > Offer > Qualify > Close',
        'Export to Excel',
        'Verified lead as valid will save it into contacts',
      ]"
    ></MNote>
  </v-container>
</template>

<script>
//BOC:[breadcrumb]
import Breadcrumb from "@/objects/breadcrumb";
//EOC
//BOC:[tabulator]
import { TabulatorFull as Tabulator } from "tabulator-tables"; //import Tabulator library
//EOC
//BOC:[faker]
import { faker } from "@faker-js/faker";
//EOC
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: mapState({
    //
  }),
  props: [
    //
  ],
  data: () => ({
    //BOC:[breadcrumb]
    breadcrumb: new Breadcrumb(),
    //EOC
    step: 2,
  }),
  created() {
    //BOC:[breadcrumb]
    this.breadcrumb.add({
      text: "Dashboard",
      to: { name: "PageConsoleDashboard" },
      exact: true,
    });
    this.breadcrumb.add({
      text: "Services",
      to: { name: "PageServiceBrowse" },
      exact: true,
    });
    this.breadcrumb.add({
      text: "Leads",
      to: { name: "PageServiceDashboard", params: { serviceKey: "lead" } },
      exact: true,
    });
    this.breadcrumb.add({
      text: "Leads",
      to: { name: "MockupLeadLeadBrowse" },
      exact: true,
    });
    //EOC
  },
  mounted() {
    var data = [];
    for (let i = 1; i <= 100; i++) {
      data.push({
        id: i,
        companyName: faker.company.name(),
        name: faker.person.fullName(),
        position: faker.person.jobTitle(),
        email: faker.internet.email(),
        phone: faker.phone.number(),
        status: "New Lead",
        bd: ["Keith", "Karmen", "Tnesh"][Math.round(Math.random() * 0)],
        source: ["Webinar", "Facebook"][Math.round(Math.random() * 1)],
        timestampAssigned: faker.date.anytime(),
        //isVerified: [true, false, null][Math.round(Math.random() * 2)],
        commission: 500,
      });
    }
    this.tabulator = new Tabulator(this.$refs.table, {
      layout: "fitColumns",
      pagination: true,
      paginationSize: 5,
      data: data, //set initial table data
      columns: [
        {
          title: "Assigned",
          field: "timestampAssigned",
        },
        { title: "Company", field: "companyName", editor: "input" },
        { title: "Name", field: "name", editor: "input" },
        { title: "Position", field: "position", editor: "input" },
        { title: "Email", field: "email", editor: "input" },
        { title: "Phone", field: "phone", editor: "input" },
        {
          title: "BD",
          field: "bd",
          editor: "list",
          editorParams: {
            values: ["Keith", "Karmen", "Tnesh"],
          },
        },
        {
          title: "Status",
          field: "status",
          editor: "list",
          editorParams: {
            values: [
              "New Lead",
              "Tried to Contact",
              "Contacted",
              "Qualified",
              "Unqualified",
            ],
          },
          formatter: (cell) => {
            var value = cell.getValue();
            var color = "info";
            if (value == "New Lead") color = "warning";
            if (value == "Qualified") color = "success";
            if (value == "Unqualified") color = "error";
            return `<span class="v-chip theme--light v-size--x-small ${color}"><span class="v-chip__content">${value}</span></span>`;
          },
        },
        {
          title: "Source",
          field: "source",
        },
        {
          title: "Webinar Minute",
        },
        {
          title: "Last Contact",
          field: "timestampContacted",
          editor: "datetime",
        },
        {
          title: "Comment",
          field: "comment",
          editor: "textarea",
        },
        {
          title: "Locked",
          width: 100,
          editor: "date",
          formatter: (cell) => {
            var value = cell.getValue();
            if (value) return value;
            return `<button type="button" class="v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--small primary"><span class="v-btn__content">Lock</span></button>`;
          },
        },
        {
          title: "Valid",
          field: "isVerified",
          width: 100,
          editor: "list",
          editorParams: {
            values: [true, false],
          },
          formatter: (cell) => {
            var value = cell.getValue();
            if (value === false && value !== null) return "Invalid";
            if (value === true) return "Valid";
            return `<button type="button" class="v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--small primary"><span class="v-btn__content">Verify</span></button>`;
          },
        },
        {
          title: "Offer",
          width: 100,
          editor: "list",
          editorParams: {
            values: ["RM 2388", "RM 2888", "RM 4998"],
          },
          formatter: (cell) => {
            var value = cell.getValue();
            if (value) return value;
            return `<button type="button" class="v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--small primary"><span class="v-btn__content">Offer</span></button>`;
          },
        },
        {
          title: "Bonus",
        },
        {
          title: "Commission",
          field: "commission",
        },
        {
          title: "Existing Deals",
        },
        {
          title: "Result",
          width: 150,
          formatter: () => {
            return `<button type="button" class="v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--small success"><span class="v-btn__content">Won</span></button>` + `<button type="button" class="v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--small error ml-1"><span class="v-btn__content">Lost</span></button>`;
          },
        },
      ],
    });
  },
  methods: {
    //
  },
};
</script>